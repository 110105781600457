import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Layout, LayoutBrandData } from '../components/layout';
import { PageNotFound } from '../components/page-not-found';

export const query = graphql`
  query NotFoundPageData {
    defaultBrand: brandsJson(id: { eq: "corgi" }) {
      ...LayoutBrandFragment
    }
  }
`;

type QueryData = {
  defaultBrand: LayoutBrandData;
};

const NotFoundPage = ({ data }: PageProps<QueryData>) => {
  return (
    <Layout brand={data.defaultBrand}>
      <PageNotFound />
    </Layout>
  );
};

export default NotFoundPage;
